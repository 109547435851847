<template>
  <div class="bottom">
    <div class="top">
       <ul>
        <li>
          <p>产品</p>
          <div>PC收银系统</div>
          <div>手机收银/点餐系统</div>
          <div>云后台</div> 
          <div>线上商城</div> 
          <div>自建外卖</div>
          <div>小程序直播</div>  
        </li>
        <li>
          <p>关于我们</p>
          <div>公司远景</div>
          <div>公司简介</div>
          <div>主营业务</div> 
        </li>
        <li>
          <p>案例</p>
          <div>餐饮行业解决案例</div>
          <div>零售行业解决案例</div>
          <div>专卖行业解决案例</div> 
        </li>
        <li>
          <p>联系我们</p>
          <div>电话：4008-256-257</div>
          <div>邮箱：lyp84@126.com</div>
          <div>微信：13526469456</div> 
        </li>
   </ul>
    </div>
   <div class="bei">备案/许可证编号为： <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">豫ICP备2022004146号</a> </div>
  </div>
</template>

<script>
export default {
  name: 'Bottom',
  
}
</script>
<style lang="less" scoped>
.bottom{
  background:#2C303B;
  height:31vh;
  margin-top:13.8vh;
    .top{
        display: flex;
        justify-content: center;
        margin-bottom:3vh;
      ul{
        display: flex;
        margin-top:3vh;
        color:#FFFFFF;
        li{
          line-height: 3.2vh;
          margin-left:3.5vw;
          p{
            font-size: 1.8vh;
          }
          div{
            font-size: 1.6vh;
            color: #AAAAAA;
          }
        }
        li:nth-child(3){
          margin-left:9vw;
        }
      }
    }
  .bei{
    height:5vh;
    line-height: 5vh;
    font-size: 1.8vh;
    color:#FFFFFF;
    background:#2C303B;
    text-align: center;
    width:100%;
  }
}

</style>
