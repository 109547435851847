<template>
  <div class="connect">
    <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    >
       <a-form-model-item
      >
        <div class="title">公司名称</div>
        <a-input v-model="form.company_name" />
      </a-form-model-item>
       <a-form-model-item
      >
        <div class="title">公司地址</div>
        <a-input v-model="form.company_address" />
      </a-form-model-item>
      <a-form-model-item
      >
        <div class="title" prop="company_region">所属区域</div>
        <a-input v-model="form.company_region" />
      </a-form-model-item>
      <div class="connect">
        <a-form-model-item
            prop="contact_name"
          >
          <div class="title">联系人</div>
          <a-input v-model="form.contact_name" />
        </a-form-model-item>
        <a-form-model-item
           prop="contact_mobile"
          >
          <div class="title">联系方式</div>
          <a-input v-model="form.contact_mobile"/>
        </a-form-model-item>
      </div>
      <a-form-model-item
      >
        <div class="title">咨询留言</div>
        <a-textarea v-model="form.remark" class="text"/>
      </a-form-model-item>
       <a-form-model-item :wrapper-col="{ offset: 8, span: 16 }">
        <a-button type="primary" @click="onFinish">提交</a-button>
     </a-form-model-item> 
    </a-form-model>
  
  </div>
</template>
<script >
import {contactus} from '@/request/api.js';
export default {
  name: 'connect',
  data(){
    return{
      form:{
        contact_name:'',
        contact_mobile:'',
        company_name:'',
        company_address:'',
        company_region:'',
        remark:'',
      },
       rules: {
        contact_name: [
          { required: true, message: '请输入联系人', trigger: 'change' },
        ],
        contact_mobile:[
          { required: true, message: '请输入联系方式', trigger: 'change' },
          { pattern : /^1[3-9]{1}[0-9]{9}$/, message: '电话格式不正确',trigger: 'change'}
        ]
       }
    }
  },
  methods:{
    onFinish(){
       this.$refs.ruleForm.validate(valid => {
        if (valid) {
          contactus(this.form).then(res=>{if(res.data.status==200){
            this.$message.success('添加成功',1.5);
             this.$refs.ruleForm.resetFields();
             this.form.company_name="";
             this.form.company_address="";
             this.form.company_region="";
             this.form.remark="";
          }})  
        }
      });
    },
  } 
}
</script>
<style lang="less" scoped>
.connect{
  width:100%;
  display: flex;
  justify-content: center;
  /deep/.ant-form{
    width:34vw;
    .title{
      font-size: 1.8vh;
      line-height: 3vh;
      margin-bottom:2vh;
    }
  }
  /deep/.ant-input{
    border:2px solid #CCCCCC !important;
    height:5vh;
  }
  /deep/.ant-form-item{
     margin-bottom:2vh;
  }
  .connect{
    .ant-form-item:nth-child(1){
      margin-right:1vw;
      width:16.5vw;
    }
    .ant-form-item:nth-child(2){
      width:16.5vw;
    }
  }
  /deep/.ant-btn-primary{
    width:34vw;
    height:5vh;
    font-size: 2.0vh;
    background: #EB6519;
    border-color:#EB6519 ;
    margin-top:5.7vh;
  }
  /deep/.ant-col-offset-8{
    margin-left:0;
  }
}
 /deep/.ant-input:focus {
    box-shadow: none !important;
  }
  .text{
    height:17.5vh !important;
  }
</style>
